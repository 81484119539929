body {
  margin: 0;
  font-family: "GilroyBold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@font-face {
  font-family: "GilroyBold";
  src: local("GilroyBold"),
    url('./Assets/Fonts/Gilroy-Bold.ttf') format("truetype");
  font-weight: bold;
}


@font-face {
  font-family: "GilroyRegular";
  src: local("GilroyRegular"),
    url('./Assets/Fonts/Gilroy-Regular.ttf') format("truetype");
  font-weight: bold;
}


@font-face {
  font-family: "GilroyBlack";
  src: local("GilroyBold"),
    url('./Assets/Fonts/Gilroy-Black.ttf') format("truetype");
  font-weight: bold;
}


@font-face {
  font-family: "GilroyBoldItalic";
  src: local("GilroyBold"),
    url('./Assets/Fonts/Gilroy-BoldItalic.ttf') format("truetype");
  font-weight: bold;
}


@font-face {
  font-family: "GilroySemi";
  src: local("GilroyBold"),
    url('./Assets/Fonts/Gilroy-SemiBold.ttf') format("truetype");
  font-weight: bold;
}

.font-gilroy-bold {
  font-family: "GilroyBold";
}


html {
  scroll-behavior: smooth;
}